var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c(
        "SearchPanel",
        {
          attrs: { "show-btn": "", size: "mini", flex: "" },
          on: { getList: _vm.getListChange },
        },
        [
          _c(
            "div",
            [
              _c("p", [_vm._v("导航名称")]),
              _c("el-input", {
                attrs: {
                  clearable: "",
                  placeholder: "请输入名称",
                  size: "mini",
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v("导航类型")]),
              _c(
                "el-select",
                {
                  attrs: { clearable: "", size: "mini", placeholder: "自定义" },
                  on: { change: _vm.getListChange },
                  model: {
                    value: _vm.navigationType,
                    callback: function ($$v) {
                      _vm.navigationType = $$v
                    },
                    expression: "navigationType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "自定义", value: 1 } }),
                  _c("el-option", { attrs: { label: "活动", value: 3 } }),
                  _c("el-option", { attrs: { label: "榜单", value: 5 } }),
                  _c("el-option", { attrs: { label: "宝贝", value: 2 } }),
                  _c("el-option", { attrs: { label: "新品", value: 4 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v("状态")]),
              _c(
                "el-select",
                {
                  attrs: { clearable: "", size: "mini" },
                  on: { change: _vm.getListChange },
                  model: {
                    value: _vm.publishType,
                    callback: function ($$v) {
                      _vm.publishType = $$v
                    },
                    expression: "publishType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "显示", value: 1 } }),
                  _c("el-option", { attrs: { label: "隐藏", value: 0 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "button-right", type: "primary", size: "mini" },
              on: { click: _vm.onAdd },
              slot: "button-right",
            },
            [_vm._v(" 新建导航 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "header-cell-style": {
              background: "#D9DDE1",
              color: "#0D0202",
              fontWeight: 400,
            },
            "element-loading-text": "拼命加载中",
          },
        },
        [
          _c("el-table-column", { attrs: { label: "导航名称", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "导航类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.navigationType == 1
                      ? _c("div", [_vm._v(" 自定义 ")])
                      : _vm._e(),
                    scope.row.navigationType == 2
                      ? _c("div", [_vm._v(" 宝贝 ")])
                      : _vm._e(),
                    scope.row.navigationType == 3
                      ? _c("div", [_vm._v(" 活动 ")])
                      : _vm._e(),
                    scope.row.navigationType == 4
                      ? _c("div", [_vm._v(" 新品 ")])
                      : _vm._e(),
                    scope.row.navigationType == 5
                      ? _c("div", [_vm._v(" 榜单 ")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "排序", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.sort) + " "),
                    _c("el-button", {
                      attrs: {
                        type: "text",
                        icon: "el-icon-edit",
                        size: "mini",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showSetSort(scope.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.navigationType !== 2 &&
                    scope.row.navigationType !== 4
                      ? _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                          },
                          on: {
                            change: (e) => {
                              _vm.changeSwitch(e, scope.row)
                            },
                          },
                          model: {
                            value: scope.row.publishType,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "publishType", $$v)
                            },
                            expression: "scope.row.publishType",
                          },
                        })
                      : _vm._e(),
                    _vm._v("   "),
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.publishType == 1 ? "已发布" : "未发布")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "140", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.tableData && _vm.tableData.length > 0
        ? _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.currentPage,
              "page-sizes": [3, 10, 20, 30, 50],
              "page-size": _vm.pagination.nowPageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pagination.count,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.type === "add" ? "新建" : "编辑",
            visible: _vm.showDialog,
            width: "600px",
            "before-close": () => {
              ;(_vm.showDialog = false), (_vm.editData = {})
            },
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "导航类型", prop: "navigationType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "260px" },
                          attrs: {
                            placeholder: "请选择导航类型",
                            size: "mini",
                            disabled:
                              _vm.editData.navigationType === 2 ||
                              _vm.editData.navigationType === 4,
                          },
                          on: { change: _vm.changeNavigationType },
                          model: {
                            value: _vm.ruleForm.navigationType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "navigationType", $$v)
                            },
                            expression: "ruleForm.navigationType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "自定义", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "活动", value: 3 },
                          }),
                          _c("el-option", {
                            attrs: { label: "榜单", value: 5 },
                          }),
                          _c("el-option", {
                            attrs: { disabled: "", label: "宝贝", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { disabled: "", label: "新品", value: 4 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "导航名称", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: {
                          placeholder: "请输入导航名称",
                          size: "mini",
                          maxlength: "5",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.ruleForm.navigationType === 5
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "榜单名称", prop: "rankName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: {
                              placeholder: "请输入榜单名称",
                              size: "mini",
                              maxlength: "8",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.ruleForm.rankName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "rankName", $$v)
                              },
                              expression: "ruleForm.rankName",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.showTopImg("nameref")
                                },
                              },
                            },
                            [_vm._v(" 示例 ")]
                          ),
                          _c("el-image", {
                            ref: "nameref",
                            staticClass: "myImg",
                            staticStyle: { width: "0px", height: "0px" },
                            attrs: {
                              src: "https://nb-img.hzanchu.com/acimg/1639640815771.png",
                              "preview-src-list": [
                                "https://nb-img.hzanchu.com/acimg/1639640815771.png",
                                "https://nb-img.hzanchu.com/acimg/1639640818817.png",
                              ],
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ruleForm.navigationType === 5
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "榜单说明", prop: "content" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: {
                              placeholder: "请输入榜单说明",
                              size: "mini",
                              maxlength: "20",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.ruleForm.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "content", $$v)
                              },
                              expression: "ruleForm.content",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.showTopImg("nameref2")
                                },
                              },
                            },
                            [_vm._v(" 示例 ")]
                          ),
                          _c("el-image", {
                            ref: "nameref2",
                            staticClass: "myImg",
                            staticStyle: { width: "0px", height: "0px" },
                            attrs: {
                              src: "https://nb-img.hzanchu.com/acimg/1639640818817.png",
                              "preview-src-list": [
                                "https://nb-img.hzanchu.com/acimg/1639640815771.png",
                                "https://nb-img.hzanchu.com/acimg/1639640818817.png",
                              ],
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ruleForm.navigationType === 5
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "排序规则", prop: "rule" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.rule,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "rule", $$v)
                                },
                                expression: "ruleForm.rule",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: 0 } },
                                [
                                  _vm._v(" 综合 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "综合是指店铺商品销售量权重为50%，销售额权重为50%",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-warning-outline",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(" 商品销售额 "),
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v(" 商品销售量 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ruleForm.navigationType === 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "关联模板", prop: "templateId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "260px" },
                              attrs: {
                                placeholder: "请选择专题模板",
                                size: "mini",
                              },
                              model: {
                                value: _vm.ruleForm.templateId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "templateId", $$v)
                                },
                                expression: "ruleForm.templateId",
                              },
                            },
                            _vm._l(_vm.subjectList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.name,
                                  value: Number(item.id),
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ruleForm.navigationType === 2
                    ? _c("el-form-item", { attrs: { label: "展示对象" } }, [
                        _c("div", [_vm._v("店铺商家商品")]),
                      ])
                    : _vm._e(),
                  _vm.ruleForm.navigationType === 3
                    ? _c("el-form-item", { attrs: { label: "展示对象" } }, [
                        _c("div", [
                          _vm._v(
                            "店铺现有的营销活动（包括店铺优惠券、秒杀、限时折扣、拼团）"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.ruleForm.navigationType === 4
                    ? _c("el-form-item", { attrs: { label: "展示对象" } }, [
                        _c("div", [_vm._v("店铺近30天内上架的商品")]),
                      ])
                    : _vm._e(),
                  _vm.ruleForm.navigationType === 5
                    ? _c("el-form-item", { attrs: { label: "排序对象" } }, [
                        _c("div", [
                          _vm._v("对店铺上架中的商品进项TOP20的排序"),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
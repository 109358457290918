<template>
  <div class="outer">
    <SearchPanel
      show-btn
      size="mini"
      flex
      @getList="getListChange"
    >
      <div>
        <p>导航名称</p>
        <el-input
          v-model.trim="name"
          clearable
          placeholder="请输入名称"
          size="mini"
        ></el-input>
      </div>
      <div>
        <p>导航类型</p>
        <el-select
          v-model="navigationType"
          clearable
          size="mini"
          placeholder="自定义"
          @change="getListChange"
        >
          <el-option
            label="自定义"
            :value="1"
          />
          <el-option
            label="活动"
            :value="3"
          />
          <el-option
            label="榜单"
            :value="5"
          />
          <el-option
            label="宝贝"
            :value="2"
          />
          <el-option
            label="新品"
            :value="4"
          />
        </el-select>
      </div>
      <div>
        <p>状态</p>
        <el-select
          v-model="publishType"
          clearable
          size="mini"
          @change="getListChange"
        >
          <el-option
            label="显示"
            :value="1"
          />
          <el-option
            label="隐藏"
            :value="0"
          />
        </el-select>
      </div>

      <el-button
        slot="button-right"
        type="primary"
        size="mini"
        @click="onAdd"
      >
        新建导航
      </el-button>
    </SearchPanel>

    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData"
      border
      :header-cell-style="{
        background: '#D9DDE1',
        color: '#0D0202',
        fontWeight: 400
      }"
      element-loading-text="拼命加载中"
      style="width: 100%"
    >
      <el-table-column
        label="导航名称"
        prop="name"
      ></el-table-column>
      <el-table-column label="导航类型">
        <template slot-scope="scope">
          <div v-if="scope.row.navigationType == 1">
            自定义
          </div>
          <div v-if="scope.row.navigationType == 2">
            宝贝
          </div>
          <div v-if="scope.row.navigationType == 3">
            活动
          </div>
          <div v-if="scope.row.navigationType == 4">
            新品
          </div>
          <div v-if="scope.row.navigationType == 5">
            榜单
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="排序"
        width="150"
      >
        <template slot-scope="scope">
          {{ scope.row.sort }}
          <el-button
            type="text"
            icon="el-icon-edit"
            size="mini"
            @click="showSetSort(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        width="150"
      >
        <template slot-scope="scope">
          <el-switch
            v-if="scope.row.navigationType !== 2 && scope.row.navigationType !== 4"
            v-model="scope.row.publishType"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="
              (e) => {
                changeSwitch(e, scope.row);
              }
            "
          >
          </el-switch>
          &nbsp;
          <span>{{ scope.row.publishType == 1 ? "已发布" : "未发布" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="140"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="onEdit(scope.row)"
          >
            编辑
          </el-button>
          <!-- <el-button type="text" size="mini">预览</el-button> -->
        </template>
      </el-table-column>
      <!-- <el-table-column label="支付流水号"></el-table-column> -->
    </el-table>
    <el-pagination
      v-if="tableData && tableData.length > 0"
      :current-page="pagination.currentPage"
      :page-sizes="[3,10,20,30,50]"
      :page-size="pagination.nowPageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>

    <!-- 新建编辑 -->
    <el-dialog
      :title="type==='add'? '新建' : '编辑'"
      :visible.sync="showDialog"
      width="600px"
      :before-close="() => {
        showDialog = false,
        editData = {}
      }"
      :close-on-click-modal="false"
    >
      <div v-loading="btnLoading">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="导航类型"
            prop="navigationType"
          >
            <el-select
              @change="changeNavigationType"
              v-model="ruleForm.navigationType"
              placeholder="请选择导航类型"
              size="mini"
              style="width: 260px"
              :disabled="editData.navigationType === 2 || editData.navigationType === 4"
            >
              <el-option
                label="自定义"
                :value="1"
              ></el-option>
              <el-option
                label="活动"
                :value="3"
              ></el-option>
              <el-option
                label="榜单"
                :value="5"
              ></el-option>
              <el-option
                disabled
                label="宝贝"
                :value="2"
              ></el-option>
              <el-option
                disabled
                label="新品"
                :value="4"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="导航名称"
            prop="name"
          >
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入导航名称"
              size="mini"
              style="width: 260px"
              maxlength="5"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item
            v-if="ruleForm.navigationType===5"
            label="榜单名称"
            prop="rankName"
          >
            <el-input
              v-model="ruleForm.rankName"
              placeholder="请输入榜单名称"
              size="mini"
              style="width: 260px"
              maxlength="8"
              show-word-limit
            ></el-input>
            <el-button
              type="text"
              size="mini"
              @click="showTopImg('nameref')"
            >
              示例
            </el-button>
            <el-image
              ref="nameref"
              class="myImg"
              style="width: 0px; height: 0px"
              :src="'https://nb-img.hzanchu.com/acimg/1639640815771.png'"
              :preview-src-list="['https://nb-img.hzanchu.com/acimg/1639640815771.png','https://nb-img.hzanchu.com/acimg/1639640818817.png']"
            >
            </el-image>
          </el-form-item>
          <el-form-item
            v-if="ruleForm.navigationType===5"
            label="榜单说明"
            prop="content"
          >
            <el-input
              v-model="ruleForm.content"
              placeholder="请输入榜单说明"
              size="mini"
              style="width: 260px"
              maxlength="20"
              show-word-limit
            ></el-input>
            <el-button
              type="text"
              size="mini"
              @click="showTopImg('nameref2')"
            >
              示例
            </el-button>
            <el-image
              ref="nameref2"
              class="myImg"
              style="width: 0px; height: 0px"
              :src="'https://nb-img.hzanchu.com/acimg/1639640818817.png'"
              :preview-src-list="['https://nb-img.hzanchu.com/acimg/1639640815771.png','https://nb-img.hzanchu.com/acimg/1639640818817.png']"
            >
            </el-image>
          </el-form-item>
          <el-form-item
            v-if="ruleForm.navigationType===5"
            label="排序规则"
            prop="rule"
          >
            <el-radio-group v-model="ruleForm.rule">
              <el-radio :label="0">
                综合
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="综合是指店铺商品销售量权重为50%，销售额权重为50%"
                  placement="top"
                >
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-radio>
              <el-radio :label="1">
                商品销售额
              </el-radio>
              <el-radio :label="2">
                商品销售量
              </el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            v-if="ruleForm.navigationType===1"
            label="关联模板"
            prop="templateId"
          >
            <el-select
              v-model="ruleForm.templateId"
              placeholder="请选择专题模板"
              size="mini"
              style="width: 260px"
            >
              <el-option
                v-for="(item,index) in subjectList"
                :key="index"
                :label="item.name"
                :value="Number(item.id)"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="ruleForm.navigationType===2"
            label="展示对象"
          >
            <div>店铺商家商品</div>
          </el-form-item>
          <el-form-item
            v-if="ruleForm.navigationType===3"
            label="展示对象"
          >
            <div>店铺现有的营销活动（包括店铺优惠券、秒杀、限时折扣、拼团）</div>
          </el-form-item>
          <el-form-item
            v-if="ruleForm.navigationType===4"
            label="展示对象"
          >
            <div>店铺近30天内上架的商品</div>
          </el-form-item>
          <el-form-item
            v-if="ruleForm.navigationType===5"
            label="排序对象"
          >
            <div>对店铺上架中的商品进项TOP20的排序</div>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="mini"
          @click="showDialog = false"
        >取 消</el-button>
        <el-button
          type="primary"
          size="mini"
          :loading="btnLoading"
          @click="submitForm()"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      isShowSort: false, // 设置排序
      name: '',
      navigationType: '', // 导航类型
      publishType: '', // 全部
      loading: false,
      btnLoading: false,
      tableData: [],
      showDialog: false,
      type: 'add',
      editData: {},
      subjectList: [],
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      },
      ruleForm: {
        navigationType: '',
        name: '',
        rankName: '',
        content: '', // 说明
        rule: '',
        templateId: ''
      },
      rules: {
        navigationType: [
          { required: true, message: '请选择导航类型', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入导航名称', trigger: 'blur' }
        ],
        rankName: [
          { required: true, message: '请输入榜单名称', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入榜单说明', trigger: 'blur' }
        ],
        templateId: [
          { required: true, message: '请选择专题模板', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请选择商品排行榜规则', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
  },
  mounted() {
    this.getList();
    this.getSubjectList();
  },
  methods: {
    getListChange() {
      this.pagination.currentPage = 1
      this.getList()
    },
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.Navigation.page, {
          params: {
            currentPage: this.pagination.currentPage,
            pageSize: this.pagination.nowPageSize,
            name: this.name,
            navigationType: this.navigationType,
            publishType: this.publishType
          }
        })
        .then((res) => {
          if (res.code === 0) {
            this.tableData = res.data.records
            this.pagination.count = Number(res.data.total)
          } else {
            this.tableData = []
          }
          this.loading = false
        })
    },
    getSubjectList() {
      this.$axios
        .post(this.$api.tradePluginTemplate.page, {
          currentPage: 1,
          pageSize: 50
        })
        .then((res) => {
          if (res.code === 0) {
            this.subjectList = res.data.records;
          } else {
            this.subjectList = []
          }
        })
    },
    submitForm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$confirm('是否确认此操作?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.btnLoading = true;
            let params = {}; let api = this.$api.Navigation.modify;
            params = {
              name: this.ruleForm.name,
              navigationType: this.ruleForm.navigationType,
              // publishType: 0,
              templateId: this.ruleForm.navigationType == 1 ? this.ruleForm.templateId : '',
              rankName: this.ruleForm.navigationType == 5 ? this.ruleForm.rankName : '',
              content: this.ruleForm.navigationType == 5 ? this.ruleForm.content : '',
              rule: this.ruleForm.navigationType == 5 ? this.ruleForm.rule : '',
              id: this.ruleForm.id ? this.ruleForm.id : ''
            }
            this.$axios.post(api, {
              ...params
            })
              .then((res) => {
                this.btnLoading = false;
                if (res.code === 0) {
                  this.showDialog = false;
                  this.$message({
                    message: '操作成功！',
                    type: 'success'
                  })
                  this.getListChange();
                }
              })
          }).catch(() => {

          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    changeNavigationType(){
      console.log('changeNavigationType')
      this.ruleForm = {
        ...this.ruleForm,
        name: '',
        rankName: '',
        content: '', // 说明
        rule: '',
        templateId: ''
      }
    },
    onAdd() {
      this.showDialog = true;
      this.type = 'add';
      this.ruleForm = {
        navigationType: '',
        name: '',
        rankName: '',
        content: '', // 说明
        rule: '',
        templateId: ''
      }
    },
    onEdit(row) {
      this.editData = row;
      this.type = 'edit';
      this.showDialog = true;
      this.ruleForm = {
        ...row
      }
    },
    showSetSort(row) {
      this.$prompt('请输入排序值', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\d+$/,
        inputErrorMessage: '排序值必须是数字',
        closeOnClickModal: false,
        inputValue: row.sort
      }).then(({ value }) => {
        this.setSort(value, row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    setSort(value, row) {
      this.loading = true;
      this.$axios.post(this.$api.Navigation.modify_sort, {
        id: row.id,
        sort: value
      })
        .then((res) => {
          this.loading = false
          if (res.code === 0) {
            this.$set(row, 'sort', value);
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
          }
        })
    },
    changeSwitch(e, row) {
      let title = ['是否确认发布?', '是否确认隐藏?'];
      let api = this.$api.Navigation.modify_status;
      let params = {
        publishType: e,
        id: Number(row.id)
      }

      this.$confirm(e == 1 ? title[0] : title[1], '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loading = true;
          this.$axios
            .post(api, params)
            .then((res) => {
              this.loading = false;
              if (res.code === 0) {
                this.$set(row, 'publishType', e)
                this.$message({
                  message: '操作成功！',
                  type: 'success'
                })
              } else {
                e === 1 ? this.$set(row, 'publishType', 0) : this.$set(row, 'publishType', 1);
              }
            })
        })
        .catch(() => {
          e === 1 ? this.$set(row, 'publishType', 0) : this.$set(row, 'publishType', 1)
        })
    },
    showTopImg(name) {
      this.$refs[name].$el.getElementsByTagName('img')[0].click();
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getList()
    }
  }
}
</script>
<style lang="scss">
.myImg .el-icon-circle-close {
  color: #fff;
}
</style>
